import en from './locales/en.json'
import fr from './locales/fr.json'
import de from './locales/de.json'

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        },
        decimal: {
            style: 'decimal',
            maximumFractionDigits: 1
        },
        rating: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }
    },
    de: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        },
        decimal: {
            style: 'decimal',
            maximumFractionDigits: 1
        },
        rating: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }
    },
    fr: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        },
        decimal: {
            style: 'decimal',
            maximumFractionDigits: 1
        },
        rating: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }
    }
}

export default defineI18nConfig(() => ({
    legacy: false,
    globalInjection: true,
    fallbackLocale: 'fr',
    messages: {
        en,
        fr,
        de
    },
    numberFormats
}))
